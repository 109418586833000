import React from 'react'
import Layout from '../components/Layout'
import Nav from '../components/Nav'

export default function myProcess() {
    return (
        <Layout>
            <Nav></Nav>
            <section className="section--container">
                <section className="section--scrollable">
                    <h6>001. Design Philosophy</h6>
                    <h1>Great design converges <br />in the following three areas:</h1>
                    <ol>
                    <li>Being data-informed,</li>  
                    <li>Leaning into empathy,</li>
                    <li>Trusting your gut instinct.</li>
                    </ol>
                    <p>You can have all of the quantitative metrics tell a narrative that something is happening. You can also have stories from the mouth of customers telling you something too. Then there's just that good 'ol gut feel that you should implement the solution in this way. They are all right, but when viewed as a series of lenses, and layered on top of one another - that's when a great experience can be crafted.</p>
                    </section>
                <section className="section--scrollable">
                    <h6>002. UX/Design Research</h6>
                    <h1>When is the right time for research?</h1>
                    <p>The short answer: Anytime and all of the time.</p>
                    <p>Research doesn't have to a big ordeal. It can be as easy as getting on a quick call with a customer or a user to ask them about their experience. But sometimes research does call for a more systematic approach just be careful about the question you're asking and whether or not it can be achieved by research (or if it even needs to be).</p>
                </section>
                <section className="section--scrollable">
                    <h6>003. Democratizing design</h6>
                    <h1>When should I share my work?</h1>
                    <p>Share early, share often. When it's appropriate, share designs or thoughts as early as possible and share it with people with whom you think will add immediate value. The trick to ensure that the context of what you're sharing is correctly framed for those who are receiving it. Be sure to also include clear instructions on the kind of feedback you're looking for, if at all. </p>
                </section>
                <section className="section--scrollable">
                    <h6>004. Confidence interval</h6>
                    <h1>How do I know I have enough information or data to make a decision?</h1>
                    <p>Look at your data sources and look for patterns. A combination of qualitative metrics and an anecdote from a human being is more than sufficient to get the ball rolling. If there's a need to increase the confidence level or to de-risk the decision, then further investigation may need to take place.</p>
                </section>
                <section className="section--scrollable">
                    <h6>005. Tools for the task</h6>
                    <h1>Which tools do you use and when?</h1>
                    <p>Every project is different. Every problem is different. A hammer used for nails for one job works, but a hammer used for a screw won't be as useful. Sometimes you need to widen the scope of work to re-examine the problem statement, to align all stakeholders to agree on what the problem is. On the other side of the coin, it may be necessary to just roll past all of the foundational stuff and just get into the solution because context is shared, metrics are known, and now it's just about the thing.</p>
                    <p>Know where you are and adapt accordingly.</p>
                </section>
            </section>
        </Layout>
    )
}
